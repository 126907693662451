import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/mdx.js";
import CareersList from "src/components/careers-list";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageHeading = makeShortcode("PageHeading");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeading title={props.pageContext.frontmatter.title} image="working" crumbs={["TechSlice", "About", props.pageContext.frontmatter.title]} mdxType="PageHeading"></PageHeading>
    <div className="container" style={{
      maxWidth: 600,
      marginBottom: "2rem"
    }}>
      <p>{`At TechSlice, we care about our team. We invest in our employees with the same care that we invest in our clients. Our company benefits include:`}</p>
      <ul>
        <li parentName="ul">{`401k with company matching`}</li>
        <li parentName="ul">{`Healthcare (with an HSA option)`}</li>
        <li parentName="ul">{`Dental and vision add-ons`}</li>
        <li parentName="ul">{`Fully remote options for most positions`}</li>
        <li parentName="ul">{`Flexible scheduling`}</li>
      </ul>
      <p>{`Are you a go-getter looking to improve people's lives as a career? Please apply for our open positions!`}</p>
      <h2>Featured Positions:</h2>
      <CareersList mdxType="CareersList"></CareersList>
      <p style={{
        textAlign: "center"
      }}>Want to apply for a position we don't have featured here?<br />Please use this <Link style={{
          color: "var(--strong)"
        }} to="/career-detail/application-form" mdxType="Link">Application Form</Link></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      